/** @format */

import React, { Fragment } from 'react';
import base from '../base';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import $ from 'jquery';
import AddTaskForm from './AddTaskForm';
import Tasks from './Tasks';

class App extends React.Component {
	taskTitle = React.createRef();
	taskDescription = React.createRef();
	taskAssignee = React.createRef();
	taskCategory = React.createRef();
	taskPriority = React.createRef();
	currentUser = React.createRef();

	state = {
		tasks: {},
		savedTasks: {},
		startDate: new Date(),
	};

	clearTasks = () => {
		this.setState({
			tasks: {},
		});
	};

	newDeleteTask = (assignee, key) => {
		base.remove(`${assignee}/tasks/${key}`, function (err) {
			if (!err) {
			}
		});
		setTimeout(() => {
			this.getSavedTasks();
		}, 500);
	};

	markTask = (assignee, key, complete) => {
		base.update(`${assignee}/tasks/${key}/task`, {
			data: { completed: complete },
			then(err) {
				if (!err) {
				}
			},
		});
		setTimeout(() => {
			this.getSavedTasks();
		}, 500);
	};

	addTask = (task, assignee) => {
		const tasks = { ...this.state.tasks };
		tasks[`task-${Date.now()}`] = task;
		this.setState({
			tasks: tasks,
		});
		this.saveTask(task, assignee);
	};

	saveTask = (task, assignee) => {
		base.push(`${assignee}/tasks`, {
			data: { task },
			then(err) {
				if (!err) {
				} else {
					console.log(err);
				}
			},
		});
		setTimeout(() => {
			this.getSavedTasks();
		}, 500);
	};

	getSavedTasks = () => {
		base.fetch(`${this.currentUser.current.value}/tasks`, {
			context: this,
			asArray: true,
			then(data) {
				this.setState({
					savedTasks: data,
				});
			},
		});
	};

	setCurrentUser = (user) => {
		localStorage.setItem('currentUser', this.currentUser.current.value);
		setTimeout(() => {
			this.getSavedTasks();
		}, 500);
	};

	componentDidMount() {
		if (localStorage.getItem('currentUser') !== null) {
			//console.log('u: ' + localStorage.getItem('currentUser'));
			let element = document.getElementById('userSelect');
			element.value = localStorage.getItem('currentUser');
			this.getSavedTasks();
		}
		/* this.clearTasks(); */
	}

	render() {
		return (
			<React.Fragment>
				<div className='tasks'>
					<h1>
						<i className='fas fa-tasks fa-1x'></i>My To-Do List
					</h1>
					<form
						className=' needs-validation tooltip-label-top'
						style={{ color: '#f6bd66' }}
						onChange={this.setCurrentUser}
						id='userNameTaskForm'>
						<div className='form-row'>
							<div className='col'>
								{/* assignee */}
								<div className='md-form'>
									<select className='browser-default custom-select' ref={this.currentUser} id='userSelect'>
										<option value=''>Set User:</option>
										<option value='rod'>Rod</option>
										<option value='niki'>Niki</option>
									</select>
									<div className='invalid-tooltip'>A user is required!</div>
								</div>
							</div>
						</div>
					</form>
					<div style={{ margin: '12% 12%' }}>
						<button
							type='button'
							className='btn btn-primary'
							data-toggle='modal'
							data-target='#addTaskModal'
							style={{ backgroundColor: '#6d335c', border: '2px solid #f6bd66', color: '#aa8e8d', margin: '10px' }}>
							+ Create a New To-Do
						</button>
					</div>
					<Tasks
						tasks={this.state.tasks}
						savedTasks={this.state.savedTasks}
						saveTask={this.saveTask}
						clearTasks={this.clearTasks}
						newDeleteTask={this.newDeleteTask}
						markTask={this.markTask}
					/>
				</div>
				<AddTaskForm addTask={this.addTask} setCurrentUser={this.setCurrentUser} />
			</React.Fragment>
		);
	}
}

export default App;
