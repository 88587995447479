/** @format */

import Rebase from 're-base';
import firebase from 'firebase';

const firebaseApp = firebase.initializeApp({
	apiKey: 'AIzaSyDJMXEwdmfE-4c5FP1NEyNSxQBTlGdDsY8',
	authDomain: 'shared-notes-8048f.firebaseapp.com',
	databaseURL: 'https://shared-notes-8048f.firebaseio.com',
});

const base = Rebase.createClass(firebaseApp.database());

export { firebaseApp };
export default base;
