/** @format */

import React from 'react';
import $ from 'jquery';
import Task from './Task';

class Tasks extends React.Component {
	render() {
		const size = Object.keys(this.props.tasks).length;
		return (
			<React.Fragment>
				{Object.keys(this.props.savedTasks).map((key) => (
					<Task
						newDeleteTask={this.props.newDeleteTask}
						markTask={this.props.markTask}
						key={key}
						details={this.props.savedTasks[key].task}>
						{this.props.savedTasks[key].key}
					</Task>
				))}
			</React.Fragment>
		);
	}
}

export default Tasks;
