/** @format */

import React from 'react';
import $, { timers } from 'jquery';

class Task extends React.Component {
	clickTask = (e, key, assignee, completed) => {
		//e.preventDefault();
		/* console.log(
			'you clicked a task! ' +
				e.target.id +
				' the key is: ' +
				key +
				' the assignee is: ' +
				assignee +
				' and completed is: ' +
				completed
		); */

		if (e.target.classList.contains('on')) {
			e.target.classList.remove('on');
			this.props.markTask(assignee, key, false);
		} else {
			if (e.target.id !== 'mapLink') {
				e.target.classList.add('on');
				this.props.markTask(assignee, key, true);
			}
		}
	};

	deleteTask = (id, taskid, assignee) => {
		setTimeout(() => {
			this.props.newDeleteTask(assignee, taskid);
		}, 250);

		//console.log(taskid);
	};

	makeGoogleMapsLink = (address) => {
		const urifrag1 = 'https://www.google.com/maps/search/?api=1&query=';
		const urifrag2 = address;
		const urifrag3 = ' Toronto';
		return urifrag1 + urifrag2 + urifrag3;
	};

	componentDidMount() {}

	render() {
		if (this.props.details === null || this.props.details === undefined) {
			// Render loading state ...
			return (
				<React.Fragment>
					<div className='movie-item-style-2 movie-item-style-1'>loading</div>
				</React.Fragment>
			);
		} else {
			const {
				name,
				description,
				assignee,
				assigner,
				category,
				priority,
				date,
				datestring,
				completed,
				address,
			} = this.props.details;
			return (
				<React.Fragment>
					<input className='' type='checkbox' />
					{!completed && priority && (
						<label
							htmlFor='label'
							className='task'
							id={'id-' + name}
							onClick={(e) => this.clickTask(e, this.props.children, `${assignee}`, completed)}>
							{/* <div
								className='deleteMe'
								id={'del-' + name}
								onClick={(e) => this.deleteTask(`id-${name}`, this.props.children, `${assignee}`)}></div> */}

							<h1>
								{category === 'pickup' && <i className='fas fa-car fa-2x'></i>}
								{category === 'delivery' && <i className='fas fa-car fa-2x'></i>}
								{category === 'household' && <i className='fas fa-home fa-2x'></i>}
								{category === 'bram related' && <i className='fas fa-child fa-2x'></i>}
								{category}
							</h1>
							<h2 className='priority'>
								{priority === 'high' && <i className='fas fa-radiation-alt fa-1x x2'></i>}
								{priority === 'medium' && <i className='simple-icon-energy heading-icon x2'></i>}
								{priority === 'low' && <i className='simple-icon-hourglass heading-icon x2'></i>}
								{priority + ' priority'}
							</h2>
							<h2 className='title'>
								{name}
								<span>{description}</span>
							</h2>
							{address && (
								<>
									<h2 className='address'>
										{/* <span>{address}</span> */}
										<span>
											<i className='fas fa-directions fa-2x'></i>
											<a id='mapLink' className='addlink' href={this.makeGoogleMapsLink(address)}>
												{address}
											</a>
										</span>
									</h2>
									<br></br>
								</>
							)}
							<div className='icons'>
								<i
									className='xdeleteMe fas fa-trash-alt fa-3x'
									onClick={(e) => this.deleteTask(`id-${name}`, this.props.children, `${assignee}`)}></i>
								<i className='xdeleteMe circ fas fa-circle fa-3x'></i>
							</div>
							<h2 className='assigner'>
								Assigned by: {assigner === 'rod' && <i className='fas fa-male fa-2x'> </i>}{' '}
								{assigner === 'niki' && <i className='fas fa-female fa-2x'> </i>}
								{assigner}
							</h2>
						</label>
					)}
					{completed && priority && (
						<label
							htmlFor='label'
							className='task on'
							id={'id-' + name}
							onClick={(e) => this.clickTask(e, this.props.children, `${assignee}`, completed)}>
							{/* <div
								className='deleteMe'
								id={'del-' + name}
								onClick={(e) => this.deleteTask(`id-${name}`, this.props.children, `${assignee}`)}></div> */}

							<h1>
								{category === 'pickup' && <i className='fas fa-car fa-2x'></i>}
								{category === 'delivery' && <i className='fas fa-car fa-2x'></i>}
								{category === 'household' && <i className='fas fa-home fa-2x'></i>}
								{category === 'bram related' && <i className='fas fa-child fa-2x'></i>}
								{category}
							</h1>
							<h2 className='priority'>
								{priority === 'high' && <i className='fas fa-radiation-alt fa-1x x2'></i>}
								{priority === 'medium' && <i className='simple-icon-energy heading-icon x2'></i>}
								{priority === 'low' && <i className='simple-icon-hourglass heading-icon x2'></i>}
								{priority + ' priority'}
							</h2>
							<h2 className='title'>
								{name}
								<span>{description}</span>
							</h2>
							{address && (
								<>
									<h2 className='address'>
										{/* <span>{address}</span> */}
										<span>
											<i className='fas fa-directions fa-2x'></i>
											<a id='mapLink' href={this.makeGoogleMapsLink(address)}>
												{address}
											</a>
										</span>
									</h2>
									<br></br>
								</>
							)}
							<div className='icons'>
								<i
									className='xdeleteMe fas fa-trash-alt fa-3x'
									onClick={(e) => this.deleteTask(`id-${name}`, this.props.children, `${assignee}`)}></i>
								<i className='xdeleteMe circ fas fa-check-circle fa-3x'></i>
							</div>
							<h2 className='assigner'>
								Assigned by: {assigner === 'rod' && <i className='fas fa-male fa-2x'> </i>}{' '}
								{assigner === 'niki' && <i className='fas fa-female fa-2x'> </i>}
								{assigner}
							</h2>
						</label>
					)}
				</React.Fragment>
			);
		}
	}
}

export default Task;
