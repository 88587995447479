/** @format */

import React from 'react';
import $ from 'jquery';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

class AddTaskForm extends React.Component {
	state = {
		startDate: new Date(),
	};

	taskTitle = React.createRef();
	taskDescription = React.createRef();
	taskAssignee = React.createRef();
	taskCategory = React.createRef();
	taskPriority = React.createRef();
	dateRef = React.createRef();
	taskAddress = React.createRef();

	createTask = (event) => {
		event.preventDefault();

		const task = {
			name: this.taskTitle.current.value,
			description: this.taskDescription.current.value,
			assignee: this.taskAssignee.current.value,
			assigner: localStorage.getItem('currentUser'),
			category: this.taskCategory.current.value,
			address: this.taskAddress.current.value,
			priority: this.taskPriority.current.value,
			date: this.state.startDate.toUTCString(),
			dateString: this.state.startDate.toDateString(),
			completed: false,
		};

		console.log('name: ' + this.taskTitle.current.value);
		console.log('description: ' + this.taskDescription.current.value);
		console.log('assignee: ' + this.taskAssignee.current.value);
		console.log('category: ' + this.taskCategory.current.value);
		console.log('priority: ' + this.taskPriority.current.value);
		console.log('due date: ' + this.state.startDate.toUTCString());
		console.log('is complete: ' + task.completed);

		if (
			this.taskTitle.current.value !== '' &&
			/* this.taskDescription.current.value !== '' && */
			this.taskAssignee.current.value !== '' &&
			this.taskCategory.current.value !== '' &&
			this.taskPriority.current.value !== ''
		) {
			this.props.addTask(task, this.taskAssignee.current.value);
			event.currentTarget.reset();

			$('[data-dismiss=modal]').trigger({ type: 'click' });
			// this clears the form onSubmit.
		}
	};

	handleChange = (date) => {
		this.setState({
			startDate: date,
		});
	};

	handleCategoryChange = () => {
		let element = document.getElementById('addressRow');
		let adInput = document.getElementById('materialRegisterFormAddressInput');

		if (this.taskCategory.current.value === 'delivery' || this.taskCategory.current.value === 'pickup') {
			element.style.display = 'block';
			adInput.required = true;
		} else {
			element.style.display = 'none';
			adInput.required = false;
		}
	};

	clearForm = (e) => {
		let element = document.getElementById('addressRow');
		let adInput = document.getElementById('materialRegisterFormAddressInput');
		element.style.display = 'none';
		adInput.required = false;
		$('#addTaskForm').get(0).reset();
		this.setState({
			startDate: new Date(),
		});
	};

	render() {
		window.addEventListener(
			'keydown',
			function (e) {
				if (e.keyIdentifier === 'Tab' || e.keyCode === 9) {
					if (e.target.nodeName === 'INPUT' && e.target.type === 'text') {
					}
				}
			},
			true
		);

		return (
			<React.Fragment>
				<div
					className='modal fade'
					id='addTaskModal'
					tabIndex='-1'
					role='dialog'
					aria-labelledby='addSnippetModalLabel'
					aria-hidden='true'
					style={{ backgroundColor: '#f6bd66' }}>
					<div className='modal-dialog'>
						<div className='modal-content'>
							<div className='modal-body' style={{ backgroundColor: '#6d335c', color: '#f6bd66' }}>
								{/* modal content */}
								<div className='card' style={{ margin: '0 auto', backgroundColor: '#6d335c' }}>
									<h5 className='card-header info-color white-text text-center'>
										<strong>+ Create New Task</strong>
									</h5>
									{/* Card content */}
									<div className='card-body px-lg-5 pt-5' style={{ backgroundColor: '#4d244e' }}>
										{/* Form */}
										<form
											className=' needs-validation tooltip-label-top'
											style={{ color: '#f6bd66' }}
											onSubmit={this.createTask}
											id='addTaskForm'>
											{/* Title */}
											<div className='form-row'>
												<div className='col'>
													<div className='md-form'>
														<label htmlFor='materialRegisterFormTaskName'>Title:</label>{' '}
														<input
															type='text'
															id='materialRegisterFormTaskName'
															className='form-control'
															ref={this.taskTitle}
															/* style={{ paddingLeft: '50px' }} */
															autoComplete='off'
															required
															tabIndex='-1'
														/>
														<div className='invalid-tooltip'>A title is required!</div>
													</div>
												</div>
											</div>
											{/* description */}
											<div className='form-row'>
												<div className='col'>
													<div className='md-form'>
														<div className='form-group'>
															<label htmlFor='exampleFormControlTextarea2'>Description:</label>
															<textarea
																ref={this.taskDescription}
																className='form-control rounded-0'
																id='exampleFormControlTextarea2'
																rows='3'
																style={{ padding: '10px' }}></textarea>
														</div>
													</div>
												</div>
											</div>
											{/* datepicker */}
											<div className='form-row'>
												<div className='col'>
													<div className='md-form'>
														<div className='form-group'>
															<label>Due Date:</label>
															<div style={{ display: 'block', color: '#f6bd66' }}>
																{/* https://reactdatepicker.com/ */}
																<DatePicker
																	ref={this.dateRef}
																	showTimeInput
																	withPortal
																	timeFormat='HH:mm'
																	timeIntervals={15}
																	timeCaption='time'
																	dateFormat='MMMM d, yyyy h:mm aa'
																	className='hasDatepicker form-control'
																	selected={this.state.startDate}
																	onChange={this.handleChange}
																/>
															</div>
														</div>
													</div>
												</div>
											</div>

											<div className='form-row'>
												<div className='col'>
													{/* assignee */}
													<div className='md-form'>
														<select className='browser-default custom-select' required ref={this.taskAssignee}>
															<option value=''>Assign task to:</option>
															<option value='rod'>Rod</option>
															<option value='niki'>Niki</option>
														</select>
														<div className='invalid-tooltip'>An assignee is required!</div>
													</div>
												</div>
											</div>
											<div className='form-row'>
												<div className='col'>
													{/* category */}
													<div className='md-form'>
														<select
															className='browser-default custom-select'
															required
															ref={this.taskCategory}
															onChange={this.handleCategoryChange}>
															<option value=''>Choose a Category</option>
															<option value='pickup'>Pickup</option>
															<option value='delivery'>Delivery</option>
															<option value='household'>Household</option>
															<option value='bram related'>Bram Related</option>
														</select>
														<div className='invalid-tooltip'>A category is required!</div>
													</div>
												</div>
											</div>
											{/* Title */}
											<div className='form-row' id='addressRow'>
												<div className='col'>
													<div className='md-form'>
														<label htmlFor='materialRegisterFormTaskName'>Address:</label>{' '}
														<input
															type='text'
															id='materialRegisterFormAddressInput'
															className='form-control'
															ref={this.taskAddress}
															autoComplete='off'
															/* required */
															tabIndex='-1'
														/>
														<div className='invalid-tooltip'>An address is required!</div>
													</div>
												</div>
											</div>
											<div className='form-row'>
												<div className='col'>
													{/* priority */}
													<div className='md-form'>
														<select className='browser-default custom-select' required ref={this.taskPriority}>
															<option value=''>Choose a Priority</option>
															<option value='low'>Low</option>
															<option value='medium'>Medium</option>
															<option value='high' rel='true'>
																High
															</option>
														</select>
														<div className='invalid-tooltip'>A priority is required!</div>
													</div>
												</div>
											</div>
											{/* cancel */}
											<div className='form-row'>
												<div className='col'>
													<div className='md-form'>
														<button
															type='button'
															className='btn btn-info btn-block'
															data-dismiss='modal'
															style={{ backgroundColor: '#6d335c', border: '1px solid #ced4da', color: '#f6bd66' }}
															onClick={(e) => this.clearForm(e)}>
															Cancel
														</button>
													</div>
												</div>
											</div>
											{/* submit */}
											<div className='form-row'>
												<div className='col'>
													<div className='md-form'>
														<button
															id='snipSubmit'
															className='btn btn-info btn-block'
															type='submit'
															style={{
																fontSize: '1rem',
																padding: '4px',
																backgroundColor: '#6d335c',
																border: '1px solid #ced4da',
																color: '#f6bd66',
															}}
															data-target='#addSnippetModal'>
															Add Task +
														</button>
													</div>
												</div>
											</div>
										</form>

										{/* Form */}
									</div>
								</div>
								{/* modal content */}
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default AddTaskForm;
